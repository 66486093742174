@import './variables';

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; // Prevent horizontal scroll on the entire page
  box-sizing: border-box;
}

* {
  box-sizing: inherit; // Ensure all elements inherit this setting
}

// Styles for .container with responsive max-width

.container {
  max-width: $container-max-width-lg; /* or whatever max-width you prefer */
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: $breakpoint-xs) {
    max-width: 100%;
  }
  @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm - 1) {
    max-width: 540px;
  }
  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1) {
    max-width: 720px;
  }
  @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
    max-width: 960px;
  }
  @media (min-width: $breakpoint-lg) {
    max-width: 1300px;
  }
}

.container-full-width {
  width: 100%; // Ensure it occupies full width
  max-width: 100%; // Prevent it from exceeding the viewport width
  margin-right: auto;
  margin-left: auto;

  // General padding for larger screens
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 40px;
  @media (max-width: $breakpoint-xs) {
    padding-top: 20px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm - 1) {
    padding-top: 20px;
    max-width: 540px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1) {
    padding-top: 20px;
    max-width: 720px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
    max-width: 960px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

// Custom class for hiding elements on tablets and mobile devices
.do-not-show-in-tab-and-mobile {
  @media (max-width: $breakpoint-lg - 1) {
    display: none;
  }
}

.only-show-in-tab-and-mobile {
  display: none; /* Hide by default */

  @media (max-width: $breakpoint-lg - 1) {
    display: block; /* Show on screens smaller than the small breakpoint */
  }
}

blockquote {
  padding: 1rem;
  margin: 0;
  border-left: 3px solid #007ad9; /* PrimeNG blue from the Saga Blue theme */
  background-color: #f0f0f0;
  font-style: italic;
  color: #555;
}

pre {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33; /* A nice orange for code blocks */
  padding: 1rem;
  overflow: auto;
}

code {
  font-family: monospace;
  color: #d14;
}

figure {
  display: inline-block;
  text-align: center;
  max-width: 100%; // Ensures the figure does not exceed the width of its container
  margin: 0; // Removes default margins
}

figcaption {
  text-align: center;
  color: #666;
  font-style: italic;
  margin-top: 5px;
}

// Removing default outline on pButton

.p-button {
  outline: none;
  box-shadow: none;
  :focus {
    overflow: unset;
    box-shadow: none;
  }
}

// Global Border

.global-border {
  height: 1px;
  background-color: $border-color;
  width: 94%;
  margin: 0 auto;
}

.global-border-card {
  height: 1px;
  background-color: $border-color;
  width: 100%;
  margin: 1rem auto;
}

.global-small-divider {
  height: 5px;
  background-color: #007bc2;
  width: 40px;
  margin-top: 2.5rem;
}

/* Global styles for navbar to override primeNg theme */
.custom-toolbar {
  background-color: $light-theme-background; // Dark theme for header
  color: $text-color-dark;
  border: 0;
  border-radius: 0%;
  @media (max-width: $breakpoint-sm - 1) {
    box-shadow: 0 4px 6px -1px $shadow-color;
  }
}
.custom-toolbar-dashboard {
  background-color: $light-theme-background; // Dark theme for header
  color: $text-color-dark;
  border-bottom: 2px solid $shadow-color;
  border-radius: 0%;
  box-shadow: 0 4px 6px -1px $shadow-color;
}
.custom-toolbar-cover-story {
  background-color: $light-theme-background; // Dark theme for header
  color: $text-color-dark;
  border-bottom: 2px solid $shadow-color;
  border-radius: 0%;
  box-shadow: 0 4px 6px -1px $shadow-color;
}
.custom-sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh; // Full height of the viewport
  background-color: $light-theme-background; // Dark theme for sidebar
  color: $text-color-dark;
  .p-sidebar-header {
    padding-bottom: 0;
  }

  .custom-sidebar-header {
    padding: 1rem;
    padding-top: 0;
    background-color: $light-theme-background;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
      width: 50px;
    }
  }

  .sidebar-content {
    flex: 1;
    overflow-y: auto;
  }

  .sidebar-footer {
    padding: 1rem;
    background-color: $light-theme-background;
    display: flex; // Use flexbox for alignment
    justify-content: center; // Center content horizontally
    align-items: center; // Center content vertically
    width: 100%; // Full width
    border-top: 1px solid gray;
    a {
      font-size: 1rem;
      i {
        margin-right: 0.5rem;
      }
    }
    .profile-name {
      font-size: 1rem;
      margin-left: 0;
    }
  }
  .p-sidebar-footer {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
  background-color: $light-theme-background;
  .sidebar-content {
    overflow-y: auto;
  }

  .sidebar-section {
    margin-bottom: 1rem;
  }

  .sidebar-title {
    color: #aaa;
    padding: 0.5rem 1rem;
    font-weight: bold;
  }

  .sidebar-item {
    color: $text-color-dark;
    padding: 0.5rem 1rem;
    display: block; /* ensures the full area is clickable */
    text-decoration: none; /* removes the underline */
    position: relative;

    &:hover {
      background-color: #555;
    }

    .badge {
      position: absolute;
      right: 1rem;
      background-color: #007ad9;
      color: #fff;
      border-radius: 1rem;
      padding: 0.2rem 0.6rem;
      font-size: 0.75rem;
    }
  }
}

///// Advertisement global styles

/* Base styles for larger screens */
.side-small-adv-slot {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 4 / 3;
  margin: 1.5rem auto;
  background-color: #ffffff;
  border-radius: 10px; /* Slight rounding to soften edges */
  padding: 15px; /* Padding to create space inside the box */
  border: 1px solid #e0e0e0; /* Light border for a clean outline */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease; /* Smooth transition effects */

  &:hover {
    transform: translateY(-5px); /* Lift the box slightly on hover */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15); /* Increase shadow on hover */
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; /* Round corners of the image inside the box */
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.03); /* Slight zoom on image hover */
    }
  }
}

/* Responsive Adjustments */
@media (max-width: $breakpoint-sm) {
  .side-small-adv-slot {
    max-width: 100%; /* Full width on small devices */
    aspect-ratio: 4 / 3;
  }
}

@media (min-width: $breakpoint-md) {
  .side-small-adv-slot {
    max-width: 350px; /* Adjust width for medium screens */
  }
}

.blog-content,
.content-area {
  margin-top: 1rem;
  line-height: 2rem; // Default line-height for readability
  font-weight: 400;

  img {
    width: auto;
    max-width: 100%;
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  iframe {
    width: 80%;
    height: 300px;
    margin-left: 10%;
    @media (max-width: $breakpoint-sm) {
      width: 100%;
      margin: 0;
    }
  }

  div {
    margin: 0;
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 2.125rem;
    @media (max-width: $breakpoint-sm) {
      font-size: 1.1rem;
      line-height: 1.8rem;
    }

    span {
      margin: 0;
      font-size: 1.15rem;
      font-weight: 400;
      line-height: 2.125rem;
      color: #36454f;
      @media (max-width: $breakpoint-sm) {
        font-size: 1.1rem;
        line-height: 1.8rem;
      }

      b {
        font-size: 1.15rem;
        font-weight: 600;
        color: #36454f;
        @media (max-width: $breakpoint-sm) {
          font-size: 1.1rem;
        }
      }
    }

    b {
      font-size: 1.15rem;
      font-weight: 600;
      color: #36454f;
      @media (max-width: $breakpoint-sm) {
        font-size: 1.1rem;
      }
    }
  }

  // Adjust headings for mobile
  h1 {
    font-size: 1.7rem;
    font-weight: 600;
    color: #000;
    @media (max-width: $breakpoint-sm) {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    @media (max-width: $breakpoint-sm) {
      font-size: 1.4rem;
    }
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #000;
    @media (max-width: $breakpoint-sm) {
      font-size: 1.2rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
    @media (max-width: $breakpoint-sm) {
      font-size: 1rem;
    }
  }

  h5 {
    font-size: 0.9rem;
    font-weight: 600;
    color: #000;
    @media (max-width: $breakpoint-sm) {
      font-size: 0.85rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    font-weight: 600;
    color: #000;
    @media (max-width: $breakpoint-sm) {
      font-size: 0.65rem;
    }
  }

  p,
  span {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2rem; // Space out paragraphs

    @media (max-width: $breakpoint-sm) {
      font-size: 1.1rem;
      line-height: 1.8rem;
    }

    b {
      font-weight: 500;
    }
  }

  // Adjusting link styles
  a {
    color: #0047ab;
    text-decoration: none;
    background-image: linear-gradient(
      transparent,
      transparent 3px,
      #0047ab 3px,
      #0047ab 4px,
      transparent 4px
    );
    background-repeat: repeat-x;
    background-size: 2px 5px;
    background-position: 0 100%;
    span {
      color: #0047ab;
      &:hover {
        font-weight: 500;
        color: #006837;
      }
    }

    &:hover {
      color: #006837;
      font-weight: 500;
      background-image: linear-gradient(
        transparent,
        transparent 3px,
        #006837 3px,
        #006837 4px,
        transparent 4px
      );
    }
  }

  // Responsive table styles
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 16px;
    background-color: #fff;
  }

  table thead {
    background-color: #024771;
    color: #ffffff;
  }

  table th,
  table td {
    padding: 10px 15px;
    border: 1px solid #ddd;
  }

  table tbody tr:nth-child(odd) {
    background-color: #f4f4f4;
  }

  table tbody tr:hover {
    background-color: #e8e8e8;
  }

  // Responsive Design for Tables
  @media (max-width: 768px) {
    .scrollable-table {
      overflow-x: auto;
    }
    table {
      width: auto;
      min-width: 100%;
      margin-bottom: 1rem;
    }
  }
}
.about-description {
  margin-top: 1rem;
  line-height: 2rem; // Default line-height for readability
  font-weight: 400;

  div {
    margin: 0;
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 2.125rem;
    @media (max-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.8rem;
    }

    span {
      margin: 0;
      font-size: 1.15rem;
      font-weight: 400;
      line-height: 2.125rem;
      color: #36454f;
      @media (max-width: 768px) {
        font-size: 1.1rem;
        line-height: 1.8rem;
      }

      b {
        font-size: 1.15rem;
        font-weight: 600;
        color: #36454f;
        @media (max-width: 768px) {
          font-size: 1.1rem;
        }
      }
    }

    b {
      font-size: 1.15rem;
      font-weight: 600;
      color: #36454f;
      @media (max-width: 768px) {
        font-size: 1.1rem;
      }
    }
  }

  // Adjust headings for mobile
  h1 {
    font-size: 1.7rem;
    font-weight: 600;
    color: #000;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #000;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  h5 {
    font-size: 0.9rem;
    font-weight: 600;
    color: #000;
    @media (max-width: 768px) {
      font-size: 0.85rem;
    }
  }

  h6 {
    font-size: 0.7rem;
    font-weight: 600;
    color: #000;
    @media (max-width: 768px) {
      font-size: 0.65rem;
    }
  }

  p,
  span {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2rem; // Space out paragraphs

    @media (max-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.8rem;
    }

    b {
      font-weight: 500;
    }
  }

  // Adjusting link styles
  a {
    color: #0047ab;
    text-decoration: none;
    background-image: linear-gradient(
      transparent,
      transparent 3px,
      #0047ab 3px,
      #0047ab 4px,
      transparent 4px
    );
    background-repeat: repeat-x;
    background-size: 2px 5px;
    background-position: 0 100%;
    span {
      color: #0047ab;
      &:hover {
        font-weight: 500;
        color: #006837;
      }
    }

    &:hover {
      color: #006837;
      font-weight: 500;
      background-image: linear-gradient(
        transparent,
        transparent 3px,
        #006837 3px,
        #006837 4px,
        transparent 4px
      );
    }
  }

  // Responsive table styles
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 16px;
    background-color: #fff;
  }

  table thead {
    background-color: #024771;
    color: #ffffff;
  }

  table th,
  table td {
    padding: 10px 15px;
    border: 1px solid #ddd;
  }

  table tbody tr:nth-child(odd) {
    background-color: #f4f4f4;
  }

  table tbody tr:hover {
    background-color: #e8e8e8;
  }

  // Responsive Design for Tables
  @media (max-width: 768px) {
    .scrollable-table {
      overflow-x: auto;
    }
    table {
      width: auto;
      min-width: 100%;
      margin-bottom: 1rem;
    }
  }
}
