// Define your color scheme and commonly used values as variables
$dark-theme-background: #333;
$light-theme-background: #ffffff;
$blue-theme-background: #6200ea;
$gray-theme-background: #f5f8fa;
$text-color-dark: #000000;
$text-color-light: #ffffff;
$border-color: gray;
$primary-color: #024771;
$secondary-color: #ffa600;
$hover-bg-color: #555;
$hover-text-color: $text-color-light;
$hover-heading-text-color: rgb(237, 28, 36);
$badge-bg-color: $primary-color;
$badge-text-color: $text-color-light;
$toolbar-height: 40px;
$profile-image-size: 50px;
$logo-height: 50px;
$border-radius-full: 50%;
$border-width-standard: 1px;
$container-max-width-xlg: 1500px;
$container-max-width-lg: 1300px;
$container-max-width-md: 960px;
$container-max-width-sm: 720px;
$container-max-width-xs: 540px;
$shadow-color: rgba(0, 0, 0, 0.1);

// Define breakpoints
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
